import React  from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import cn from 'classnames';
import useTranslate from '../../hooks/useTranslate';

import './ArticleCard.scss';

const Articles = ({ block, className }) => {
  const { t } = useTranslate();

  return (
    <NavLink to={`/news/${block.slug}`} className={cn('articleCard', className)}>
      <div className="articleCardImg">
        <img src={`${block.thumbnail.medium}&height=300`} alt={block.title} loading="lazy" />
      </div>
      <div className="articleCardBody">
        <div className="articleCardTitle">{block.title}</div>
        <div className="articleCardDescription">{block.description}</div>
        <div className="articleCardInfo">
          {t('main.byAuthor')} <span>{block.author || 'Denys Kolomiiets'}</span> &nbsp;&#8226;&nbsp; {dayjs(block.pubDate).format("MMM D, YYYY")}
        </div>
      </div>
    </NavLink>
  );
};

export default Articles;
