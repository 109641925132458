import React  from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Section from '../ContentSection';
import useTranslate from '../../hooks/useTranslate';
import ArticleCard from './ArticleCard';

import { ReactComponent as ArrowIcon } from './img/icon-arrow.svg';

import './Articles.scss';

const Articles = () => {
  const { t } = useTranslate();
  const news = useSelector(state => state.newsList);

  return (
    <Section className="articles">
      <div className="commonTitle articlesTitle">
        {t(`main.articles`)}
        <div className="articlesLink">
          <Link to="/news">
            {t('main.allArticles')}
          </Link>
          <ArrowIcon />
        </div>
      </div>
      <div className="articlesContainer">
        {news.slice(0, 3).map(block => (
          <ArticleCard block={block} key={block.title} />
        ))}
      </div>
    </Section>
  );
};

export default Articles;
