import React  from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';

import './NewsPreview.scss';

const NewsPreview = ({ block, className }) => {
  return (
    <NavLink to={`/latest-news/${block.slug}`} className={cn('newsPreviewCard', className)}>
      <div className="newsPreviewCardImg">
        <img src={`${block.thumbnail.medium}&height=150`} alt={block.title} loading="lazy" />
      </div>
      <div className="newsPreviewCardBody">
        <div className="newsPreviewCardTitle">{block.title}</div>
        <div className="newsPreviewCardDate">
          {dayjs(block.pubDate).format("MMM D, YYYY")}
        </div>
      </div>
    </NavLink>
  );
};

export default NewsPreview;
