export const saveNews = {
  type: 'SAVE_NEWS'
};

export const saveLatestNews = {
  type: 'SAVE_LATEST_NEWS'
};

export const saveCategoryNews = {
  type: 'SAVE_CATEGORY_NEWS'
};

export const savePages = {
  type: 'SAVE_PAGES'
};
