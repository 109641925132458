import React from 'react';

const useClickOutside = (
  ref,
  isOpen,
  handler,
  dependencies,
) => {
  const handleClickOutside = React.useCallback((ev) => {
    if (isOpen && ref.current && !ref.current.contains(ev.target)) {
      handler();
    }
  }, [ref, isOpen]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [handleClickOutside, dependencies]);

  return null;
};

export default useClickOutside;
