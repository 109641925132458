import React  from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import cn from 'classnames';

import './NewsCard.scss';

const NewsCard = ({ block, className }) => {
  return (
    <NavLink to={`/latest-news/${block.slug}`} className={cn('newsCard', className)}>
      <div className="newsCardImg">
        <img src={`${block.thumbnail.medium}&height=320`} alt={block.title} />
        <div className="newsCardDateBanner">
          <span>{dayjs(block.pubDate).format("D")}</span>
          {dayjs(block.pubDate).format("MMM")}
        </div>
      </div>
      <div className="newsCardBody">
        <div className="newsCardTitle">{block.title}</div>
        <div className="newsCardDescription">{block.description}</div>
      </div>
    </NavLink>
  );
};

export default NewsCard;
