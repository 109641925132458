import React, { useEffect, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';

import { fetchLatestNews, fetchNews, fetchMain } from '../../redux/api';
import { saveLatestNews, saveNews } from '../../redux/actions';

import { RUSSIA_PHOTOS } from '../ImagesFromUA/ImagesFrom';
import Links from '../Socials/Links';
import News from '../News/News';
import Loader from '../Loader/Loader';
import Counter from '../Counter';
import Losses from '../Counter/Losses';
import Articles from '../Articles/Articles';
import Report from '../Report';
import World from '../World';
import Help from '../Help';
import Poster from '../Poster';

const ImagesFrom = loadable(() => import('../ImagesFromUA/ImagesFrom'));

const Layout = () => {
  const data = useSelector(state => state.mainPage);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchMain().then(res => dispatch({type: 'SAVE_MAIN', data: res}));
    fetchNews().then(res => dispatch({...saveNews, news: res}));
    fetchLatestNews().then(res => dispatch({...saveLatestNews, news: res}));
  }, []);

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
        {`{
          "@context": "https://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "News",
            "item": "https://closetheskyoverukraine.com/latest-news"
          }, {
            "@type": "ListItem",
            "position": 2,
            "name": "Articles",
            "item": "https://closetheskyoverukraine.com/news"
          }, {
            "@type": "ListItem",
            "position": 3,
            "name": "Donate Ukraine",
            "item": "https://closetheskyoverukraine.com/donate"
          }, {
            "@type": "ListItem",
            "position": 4,
            "name": "War in Numbers",
            "item": "https://closetheskyoverukraine.com/war-in-ukraine"
          }]
        }`}
        </script>
        <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "What is no-fly zone?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "It is a Humanitarian operation where military forces are not involved directly and Intercepting flying threats.
              A no-fly zone means that any object detected in the airspace will be intercepted by participating countries' aircraft or air defense systems and expelled from Ukraine or shot down."
            }
          },{
            "@type": "Question",
            "name": "Can no-fly zone cause World War 3 or nuclear war?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A no-fly zone will only be imposed over Ukrainian territory.
              It is a reactionary defense mechanism to secure humanitarian corridors and comply with international norms of warfare.
              Closing the skies is not an act of aggression against Russia."
            }
          },{
            "@type": "Question",
            "name": "Will no-fly zone cause direct confrontation with Russia?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "For example, a no-fly zone could also include a partial closure of the skies over Western Ukraine.
              This would prevent cruise missile strikes and airstrikes on civilian cities in the rear.
              Furthermore, it would provide security for NATO pilots and air defense, as they would operate outside the Russian air defense zone."
            }
          }]
        }`}
        </script>
        <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "NewsMediaOrganization",
          "name": "Sky Over Ukraine",
          "url": "https://closetheskyoverukraine.com",
          "logo": "https://closetheskyoverukraine.com/favicon.ico",
          "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+15715172422",
          "contactType": "customer service",
          "availableLanguage": ["en","es","German","Hebrew","Italian","Polish","Portuguese","Turkish","Chinese"]
        },
          "sameAs": [
          "https://www.facebook.com/Close-The-Sky-Over-Ukraine-101051235880413/",
          "https://twitter.com/CloseUkraineSky",
          "https://www.instagram.com/close.the.sky.over.ukraine/",
          "https://www.reddit.com/r/CloseUkraineSky/",
          "https://t.me/skyoverukraine",
          "https://www.linkedin.com/company/close-the-sky-over-ukraine",
          "https://www.tiktok.com/@close.sky.over.ukraine"
          ]
        }`}
        </script>
      </Helmet>
      {data === null ?
        <Loader />
          :
        <>
          <Links className="linksDesktop" />
          <Poster />
          <Counter />
          <Losses />
          <News />
          <Articles />
          <ImagesFrom type={RUSSIA_PHOTOS} />
          <Help />
          <Report />
          <World />
        </>
      }
    </div>
  );
};

export default Layout;
