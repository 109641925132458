import React from 'react';

import './Loader.scss';

const Loader = () => {
  return (
    <div className="spinner">
      <svg>
        <circle cx="20" cy="20" r="18"></circle>
      </svg>
    </div>
  );
};

export default Loader;
