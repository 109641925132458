import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useTranslate from '../../hooks/useTranslate';
import useClickOutside from '../../hooks/useClickOutside';
import Section from '../ContentSection';

import { ReactComponent as IconKids } from './img/kids.svg';
import { ReactComponent as IconInfo } from './img/info.svg';

import './Counter.scss';

const Counter = () => {
  const [informer, setInformer] = useState(false);
  const informerRef = useRef();
  const { t, content } = useTranslate();
  useClickOutside(informerRef, informer, () => setInformer(false));
  const data = useSelector(state => state.mainPage);

  const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return (
    <Section className="counter">
      <div className="counterBody">
        <div className="counterTitle">{t('main.victims')}</div>
        <div className="counterContainer">
          <div className="counterItem">
            <div className="counterValue">{formatNumber(data.civCasualties)}</div>
            <div className="counterLabel">
              {t('main.civVictims')}
            </div>
          </div>
          <div className="counterItem">
            <div className="counterValue">
              <div className="counterIcon"><IconKids /></div>
              <span>{formatNumber(data.childCasualties)}</span>
            </div>
            <div className="counterLabel">
              {t('main.childVictims')}
            </div>
          </div>
        </div>
      </div>
      <div className="counterCurrent" onClick={() => setInformer(val => !val)} ref={informerRef}>
        <div className="counterInformer">
          {t('main.officials')}
          <IconInfo onMouseEnter={() => setInformer(true)} onMouseLeave={() => setInformer(false)} />
        </div>
        {informer && <div className="counterInfo">
          {content('main.ohchr')}
        </div>}
      </div>
    </Section>
  );
};

export default Counter;
