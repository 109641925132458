import React from 'react';
import { Link } from 'react-router-dom';

import Section from '../ContentSection';
import Button from '../Button';
import useTranslate from '../../hooks/useTranslate';

import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as Heart } from '../../assets/heart.svg';

import './Help.scss';

const Help = () => {
  const { t } = useTranslate();

  return (
    <Section className="help">
      <div className="commonTitle">{t('help.title')}</div>
      <div className="helpCards">
        <Link to="/donate" className="helpCard">
          <div className="helpCardTitle">{t('main.donate')}</div>
          <div className="helpCardInfo">{t('main.donateInfo')}</div>
          <Arrow className="helpCardArrow" />
        </Link>
        <Link to="/post-in-social-media" className="helpCard">
          <div className="helpCardTitle">{t('main.post')}</div>
          <div className="helpCardInfo">{t('main.postInfo')}</div>
          <Arrow className="helpCardArrow" />
        </Link>
        <Link to="/influence-authorities-directly" className="helpCard">
          <div className="helpCardTitle">{t('main.influence')}</div>
          <div className="helpCardInfo">{t('main.influenceInfo')}</div>
          <Arrow className="helpCardArrow" />
        </Link>
      </div>
      {/*<Button to="/help-ukraine" className="helpBtn">*/}
      {/*  <Heart />*/}
      {/*  {t('main.allHelp')}*/}
      {/*</Button>*/}
    </Section>
  );
};

export default Help;
