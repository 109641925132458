import React from 'react';
import { useSelector } from 'react-redux';

import Section from '../ContentSection';
import useTranslate from '../../hooks/useTranslate';

import './Report.scss';

const dataMap = [
  [ 'peopleInNeed', 'peopleTargeted', 'refugees' ],
  [ 'peopleReached', 'displaced' ]
];
const dataMapMob = [
  [ 'peopleInNeed', 'peopleTargeted' ],
  [ 'refugees', 'peopleReached' ],
  [ 'displaced' ],
];

const Report = () => {
  const { t } = useTranslate();

  const data = useSelector(state => state.mainPage);
  const percent = data['fundingReceived'] / data['fundingRequired'] * 100;

  const getPeople = (mob = false) => {
    const rows = mob ? dataMapMob : dataMap;
    return rows.map((row, idx) => (
      <div className="reportPeopleRow" key={`row-${idx + (mob ? 1 : 5)}`}>{row.map(item => (
        <div className="reportPeopleItem" key={item}>
          <span>{data[item]}</span>
          <span>{t(`main.${item}`)}</span>
        </div>
      ))}</div>
    ));
  };

  return (
    <Section className="report">
      <div className="commonTitle">{t('main.report')}</div>
      <div className="commonInfo">{t('main.grateful')}</div>
      <div className="reportContainer">
        <div className="reportPeople">
          {getPeople()}
        </div>
        <div className="reportPeopleMob">
          {getPeople(true)}
        </div>

        <div className="reportFunding">
          <div className="reportFundingRow">
            <div className="reportFundingItem">
              <span>${(data['fundingRequired'] / 1000).toFixed(1)}B</span>
              <span>{t('main.fundingRequired')}</span>
            </div>
            <div className="reportFundingItem">
              <span>${(data['fundingReceived'] / 1000).toFixed(1)}B</span>
              <span>{t('main.fundingReceived')}</span>
            </div>
          </div>
          <div className="reportFundingRow">
            <div className="reportFundingPercent">
              <svg height="20" width="20" viewBox="0 0 20 20">
                <circle r="10" cx="10" cy="10" fill="white" />
                <circle r="5" cx="10" cy="10" fill="transparent"
                        stroke="#c51010"
                        strokeWidth="10"
                        strokeDasharray={`${percent * 0.31415} 31.4`}
                        transform="rotate(-90) translate(-20)" />
              </svg>
              <span>{Math.floor(percent)} %</span> {t('main.progress')}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Report;
