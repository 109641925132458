import React  from 'react';
import { Link } from 'react-router-dom';

import Section from '../ContentSection';

import bannerMob from './img/bannerMob.jpg';
import bannerWeb from './img/bannerWeb.jpg';

import './Poster.scss';

export default () => {
  return (
    <Section className="poster">
      <Link to="/fundraising">
        <img src={bannerMob} alt="Urgent Fundraising!" className="posterMob" />
        <img src={bannerWeb} alt="Urgent Fundraising!" className="posterWeb" />
      </Link>
    </Section>
  );
};
