import React, { useEffect, useState } from 'react';
import Flag from 'react-flagkit';
import { useSelector } from 'react-redux';

import { fetchWorld } from '../../redux/api';
import Section from '../ContentSection';
import Button from '../Button';
import Loader from '../Loader/Loader';
import useTranslate from '../../hooks/useTranslate';

import './World.scss';

const World = () => {
  const { t } = useTranslate();
  const [data, setData] = useState(null);
  const [toShow, setToShow] = useState(5);
  const adminData = useSelector(state => state.mainPage);

  useEffect(() => {
    let isMounted = true;
    fetchWorld().then(data => isMounted && setData(data));

    return () => isMounted = false;
  }, []);

  if (data === null) {
    return (
      <Section className="world">
        <Loader />
      </Section>
    );
  }

  const total = data.map(country => [country.military, country.humanitarian, country.money]).flat();
  const getPercent = (x) => {
    if (x === Math.min(...total)) {
      return 5;
    }
    if (x === Math.max(...total)) {
      return 90;
    }
    if (Math.max(...total.filter(el => el !== Math.max(...total))) / Math.max(...total) < 0.5) {
      return ((x - Math.min(...total)) / ((Math.max(...total) * 0.8) - Math.min(...total)) * 85) + 5;
    }
    return ((x - Math.min(...total)) / (Math.max(...total) - Math.min(...total)) * 85) + 5;
  };

  return (
    <Section className="world">
      <div className="commonTitle">{t('main.worldHelps')}</div>
      <div className="commonInfo">{t('main.countries', { day : adminData.reportDate })}</div>
      <div className="worldContainer">
        <div className="worldReport">
          {data.slice(0, toShow).map(item => (
            <React.Fragment key={item.countryCode}>
              <div className="worldCountry">
                {item.countryName}
                <Flag country={item.countryCode} />
              </div>
              <div className="worldData">
                <div className="worldDataRow">
                  <span style={{width: `${getPercent(item.money)}%`}}/> {item.money}
                </div>
                <div className="worldDataRow">
                  <span style={{width: `${getPercent(item.humanitarian)}%`}}/> {item.humanitarian}
                </div>
                <div className="worldDataRow">
                  <span style={{width: `${getPercent(item.military)}%`}}/> {item.military}
                </div>
              </div>
            </React.Fragment>
          ))}

        </div>
        <div className="worldLegend">
          <div className="worldLegendRow"><span/> {t('main.money')} </div>
          <div className="worldLegendRow"><span/> {t('main.hum')}</div>
          <div className="worldLegendRow"><span/> {t('main.military')}</div>
        </div>
      </div>
      {data.length > toShow && <Button onClick={() => setToShow(val => val + 5)} className="worldBtn">
        {t('main.showMore')}
      </Button>}
    </Section>
  );
};

export default World;
