import React  from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Section from '../ContentSection';
import useTranslate from '../../hooks/useTranslate';
import NewsCard from './NewsCard';
import NewsPreview from './NewsPreview';

import { ReactComponent as ArrowIcon } from './img/icon-arrow.svg';

import './News.scss';

const News = () => {
  const { t } = useTranslate();
  const latestNews = useSelector(state => state.latestNewsList);
  const newsList = latestNews.filter(i => !i.isInSlider);

  return (
    <Section className="news">
      <div className="commonTitle newsTitle">
        {t(`main.breakingNews`)}
        <div className="newsLink">
          <Link to={`/latest-news`}>
            {t(`main.allNews`)}
          </Link>
          <ArrowIcon />
        </div>
      </div>

      <div className="newsBody">
        <div className="newsLarge">
          {newsList.slice(0, 2).map(block => (
            <NewsCard key={block.title} block={block} />
          ))}
        </div>
        <div className="newsSmall">
          {newsList.slice(2, 5).map(block => (
            <NewsPreview block={block} key={block.title} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default News;
