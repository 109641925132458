import React from 'react';
import { useSelector } from 'react-redux';

import Section from '../ContentSection';
import useTranslate from '../../hooks/useTranslate';

import { ReactComponent as Army } from './img/army.svg';
import { ReactComponent as Tank } from './img/tank.svg';
import { ReactComponent as Air } from './img/air.svg';
import { ReactComponent as Ship } from './img/ship.svg';

import './Losses.scss';

const lossesMap = [
  {
    img: Army,
    type: 'Manpower',
    key: 'army',
  },
  {
    img: Tank,
    type: 'Tanks',
    key: 'tank',
  },
  {
    img: Air,
    type: 'Aircraft',
    key: 'air',
  },
  {
    img: Ship,
    type: 'Ships and boats',
    key: 'ship',
  },
]

const Losses = () => {
  const data = useSelector(state => state.mainPage);
  const { t } = useTranslate();

  return (
    <Section className="losses">
      <div className="lossesTitle">{t('main.losses')}</div>
      <div className="lossesContainer">
        <div className="lossesInfo">
          {lossesMap.map(item => {
            const Icon = item.img;
            const count = data[`${item.key}Loss`];
            const countDaily = data[`${item.key}LossDaily`];
            return (
              <div className="lossesInfoItem" key={item.type}>
                <Icon />
                <div className="lossesInfoBody">
                  <div className="lossesInfoCount">
                    {count}
                    {countDaily && <div className="lossesInfoDelta">
                      <span>&#8593;</span> {countDaily}
                    </div>}
                  </div>
                  <div className="lossesInfoType">
                    {t(`main.losses${item.key.slice(0,1).toUpperCase() + item.key.slice(1)}`)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Section>
  );
};

export default Losses;
