import fetch from 'node-fetch';
const BASE_URL = 'https://gh5y8bvt6h9m.closetheskyoverukraine.com/api';

const ARTICLES_ENDPOINT_FIELDS = '&fields[0]=title&fields[1]=description&fields[2]=slug&fields[3]=label&fields[4]=pubDate&fields[5]=publishedAt&fields[6]=isInSlider&fields[7]=author';
const NEWS_ENDPOINT_FIELDS = '&fields[0]=title&fields[1]=description&fields[2]=slug&fields[3]=label&fields[4]=pubDate&fields[5]=publishedAt&fields[6]=isInSlider&fields[7]=category';
const endpoints = {
  MAIN_ENDPOINT: '/main-page',
  WORLD_ENDPOINT: '/world-helps',
  NEWS_ENDPOINT: `/news-pages?populate=*&pagination[pageSize]=100&sort=publishedAt%3Adesc${ARTICLES_ENDPOINT_FIELDS}`,
  LATEST_NEWS_ENDPOINT: `/short-news-pages?populate=*&pagination[pageSize]=100&sort=publishedAt%3Adesc${NEWS_ENDPOINT_FIELDS}`,
  LATEST_NEWS_MORE_ENDPOINT: `/short-news-pages?populate=*&pagination[pageSize]=10&sort=publishedAt%3Adesc${NEWS_ENDPOINT_FIELDS}`,
  HELP_ENDPOINT: '/help-ukraine?populate=donationsList'
}

const processLatestNews = (data) => {
  return data.map(item => ({
    id: item.id,
    ...item.attributes,
    pubDate: item.attributes.pubDate || item.attributes.publishedAt,
  })).sort((a, b) => a.pubDate > b.pubDate ? -1 : 1) || [];
};

export const fetchNews = async () => {
  let mock = [];

  if (typeof window !== 'undefined' && window?.location.pathname !== '/') {
    mock = (await import('./newsMock')).default;
  }

  try {
    const fetchResult = await fetch(`${BASE_URL}${endpoints.NEWS_ENDPOINT}`).then(response => response.json());
    const data = fetchResult?.data?.map(item => ({
      id: item.id,
      ...item.attributes,
    })).sort((a, b) => a.pubDate > b.pubDate ? -1 : 1) || [];
    return [...data, ...mock];
  } catch (error) {
    return mock;
  }
};

export const fetchLatestNews = async () => {
  try {
    const fetchResult = await fetch(`${BASE_URL}${endpoints.LATEST_NEWS_ENDPOINT}`).then(response => response.json());
    return processLatestNews(fetchResult?.data);
  } catch (error) {
    return [];
  }
};

export const fetchMain = async () => {
  try {
    const fetchResult = await fetch(`${BASE_URL}${endpoints.MAIN_ENDPOINT}`).then(response => response.json());
    return {
      ...fetchResult?.data.attributes,
    };
  } catch (error) {
    return [];
  }
};

export const fetchWorld = async () => {
  try {
    const fetchResult = await fetch(`${BASE_URL}${endpoints.WORLD_ENDPOINT}`).then(response => response.json());
    return fetchResult?.data?.map(item => ({
      id: item.id,
      ...item.attributes,
    })).sort((a, b) => a.place > b.place ? 1 : -1) || [];
  } catch (error) {
    return [];
  }
};

export const fetchMoreLatestNews = async (page) => {
  try {
    const fetchResult = await fetch(`${BASE_URL}${endpoints.LATEST_NEWS_MORE_ENDPOINT}&pagination[page]=${page}`).then(response => response.json());
    return processLatestNews(fetchResult?.data);
  } catch (error) {
    return [];
  }
};

export const fetchNewsByCategories = async (cat) => {
  try {
    const fetchResult1 = await fetch(`${BASE_URL}${endpoints.LATEST_NEWS_ENDPOINT}&filters[category][$eq]=${cat}`).then(response => response.json());
    const fetchResult2 = await fetch(`${BASE_URL}${endpoints.LATEST_NEWS_ENDPOINT}&filters[label][$eq]=${cat}`).then(response => response.json());
    return processLatestNews([...fetchResult1?.data, ...fetchResult2?.data]);
  } catch (error) {
    return [];
  }
}

export const fetchCurrentPage = async (slug) => {
  const endpoint = '/local-help-pages?populate=*&filters[url][$eq]=';
  return makeFetch(slug, endpoint);
};

export const fetchStaticPage = async (slug) => {
  const endpoint = '/static-pages?populate=*&filters[url][$eq]=';
  return makeFetch(slug, endpoint);
};

export const fetchNewsPage = async (slug, isLatest) => {

  const endpoint = `/${isLatest ? 'short-news-pages' : 'news-pages'}?populate=*&filters[slug][$eq]=`;
  const fetchRes = await makeFetch(slug, endpoint);

  if (fetchRes) {
    return {
      ...fetchRes,
      pubDate: fetchRes.pubDate || fetchRes.publishedAt,
    }
  }

  const mock = await import('./newsMock');
  const mockRes = mock.default.find(el => el.slug.includes(slug));

  if (!mockRes) return null;
  return {
    ...mockRes,
    pubDate: mockRes.pubDate || mockRes.publishedAt,
  }
};

export const fetchHelpUa = async () => {
  try {
    const fetchResult = await fetch(`${BASE_URL}${endpoints.HELP_ENDPOINT}`).then(response => response.json());
    return {
      ...fetchResult?.data.attributes,
    };
  } catch (error) {
    return [];
  }
};

const makeFetch = async (slug, endpoint) => {
  const normalizedSlug = slug.slice(-1) === '/' ? slug.slice(0, -1) : slug;
  try {
    const fetchResult = await fetch(`${BASE_URL}${endpoint}${normalizedSlug}`).then(response => response.json());
    return fetchResult?.data?.map(item => ({
      id: item.id,
      ...item.attributes,
    }))[0] || null;
  } catch (error) {
    return null;
  }
};
